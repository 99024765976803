import arrowRight from 'assets/icons/arrow-right.svg';
import arrowBottom from 'assets/icons/arrow-bottom.svg';
import angleDown from 'assets/icons/angle-down.svg';
import logout from 'assets/icons/logout.svg';
import start from 'assets/icons/start.svg';
import like from 'assets/icons/like.svg';
import restart from 'assets/icons/restart.svg';
import play from 'assets/icons/play.svg';
import search from 'assets/icons/search.svg';
import close from 'assets/icons/close.svg';
import lock from 'assets/icons/lock.svg';
import enter from 'assets/icons/enter.svg';
import telegram from 'assets/icons/telegram.svg';
import camera from 'assets/icons/camera.svg';
import loader from 'assets/icons/loader.png';
import success from 'assets/icons/success.png';
import eyeOpen from 'assets/icons/eye-open.svg';
import eyeOff from 'assets/icons/eye-off.svg';
import logOut from 'assets/icons/log-out.svg';
import checkbox from 'assets/icons/checkbox.svg';

const mapping = {
    'arrow-right': arrowRight,
    'arrow-bottom': arrowBottom,
    'angle-down': angleDown,
    logout,
    start,
    like,
    restart,
    play,
    search,
    close,
    lock,
    enter,
    telegram,
    camera,
    loader,
    success,
    'eye-open': eyeOpen,
    'eye-off': eyeOff,
    'log-out': logOut,
    checkbox,
};

export { mapping };
