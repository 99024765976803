import { memo } from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'assets/images/avatar.webp';
import Avatar2x from 'assets/images/avatar@2x.webp';
import { getUser } from 'store/user';
import * as C from 'styles/components';
import * as S from './styles';

interface UserNameProps {
    variant: 'dark' | 'light';
}

export const UserMenu = memo((props: UserNameProps) => {
    const { variant } = props;
    const user = useSelector(getUser);

    return (
        <S.StyledLink to="/profile">
            <S.UserName variant={variant}>
                <S.Welcome>Привет, {user?.name}</S.Welcome>
                <C.Avatar src={Avatar} srcSet={`${Avatar2x} 2x`} alt="" />
            </S.UserName>
        </S.StyledLink>
    );
});
