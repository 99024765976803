import { UserWidget } from 'components/UserWidget';
import React, { memo } from 'react';
import { Logo } from 'components/Logo';
import { useMatchMedia } from 'utils/hooks';
import { laptopUp } from 'config';
import * as S from './styles';

export interface HeaderProps {
    showGradientBg?: boolean;
    showUserWidget?: boolean;
}

export const Header = memo((props: HeaderProps) => {
    const { showGradientBg = false, showUserWidget = false } = props;

    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <S.Header>
            <S.HeaderContainer>
                <S.LogoLink to="/">
                    <Logo
                        variant={
                            !laptopMatch || showGradientBg ? 'dark' : 'light'
                        }
                        width={160}
                        height={29}
                    />
                </S.LogoLink>
                {showUserWidget && (
                    <UserWidget variant={showGradientBg ? 'dark' : 'light'} />
                )}
            </S.HeaderContainer>
            {laptopMatch && showGradientBg && <S.BlurBg />}
        </S.Header>
    );
});
