import { Button } from 'components/Button';
import { InputColors, InputSizes, InputVariants } from 'components/Input/types';
import { StyledVariants } from 'types/StyledVariants';
import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';

interface WrapperProps {
    variant: InputVariants;
    wrapperSize: InputSizes;
    color: InputColors;
    rounded: boolean;
    isFocus: boolean;
    isDisabled: boolean;
}

interface InputProps {
    inputSize: InputSizes;
}

interface StyledButtonProps {
    $isOpacity: boolean;
}

const wrapperSizes: StyledVariants<InputSizes> = {
    s: css`
        padding-left: 24px;
        padding-right: 12px;
    `,
    m: css`
        padding-left: 12px;
        padding-right: 12px;
    `,
};

const wrapperSizesMobile: StyledVariants<InputSizes> = {
    s: css`
        padding-left: 24px;
        padding-right: 12px;
    `,
    m: css`
        padding-left: 8px;
        padding-right: 8px;
    `,
    l: css`
        padding-left: 8px;
        padding-right: 8px;
    `,
};

const inputSizes: StyledVariants<InputSizes> = {
    s: css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 17px;
    `,
    m: css`
        font-size: ${({ theme }) => theme.fontSize.default}px;
        font-weight: 500;
        padding-top: 20px;
        padding-bottom: 20px;
    `,
    l: css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
        font-weight: 500;
        padding-top: 18px;
        padding-bottom: 18px;
    `,
};

const inputSizesMobile: StyledVariants<InputSizes> = {
    s: css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 17px;
    `,
    m: css`
        font-size: ${({ theme }) => theme.fontSize.xs}px;
        font-weight: 500;
        padding-top: 16px;
        padding-bottom: 16px;
    `,
    l: css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
        font-weight: 500;
        padding-top: 18px;
        padding-bottom: 18px;
    `,
};

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;

    ${({
        variant = InputVariants.outline,
        color = InputColors.white,
        rounded,
        isFocus,
        theme,
    }) => {
        const hoverColorMap: { [key in InputColors]?: string } = {
            lightGray: theme.colors.accent,
            dark: theme.colors.accent,
        };

        const themeColor = theme.colors[color];
        const hoverColor = hoverColorMap[color];

        switch (variant) {
            case InputVariants.solid:
                return css`
                    background: ${themeColor};
                    border-radius: ${rounded && '12'}px;
                `;
            case InputVariants.outline:
                return css`
                    border: 2px solid ${isFocus ? hoverColor : themeColor};
                    border-radius: ${rounded && '16'}px;
                `;
            default:
                return css``;
        }
    }}

    ${({ wrapperSize }) => wrapperSizesMobile[wrapperSize]}
    ${({ wrapperSize }) => laptopUpMedia(wrapperSizes[wrapperSize])}
    
    ${({ isDisabled }) => isDisabled && 'opacity: 0.3'}
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
    opacity: ${({ $isOpacity }) => $isOpacity && '0.4'};
    padding: 8px;

    &:disabled {
        opacity: ${({ $isOpacity }) => ($isOpacity ? '0.5' : '1')};
    }
`;

export const Input = styled.input<InputProps>`
    flex: 1 1 100%;
    font-family: ${({ theme }) => theme.familyPrimary};
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 1;
    border: 0;
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;

    &:disabled {
        pointer-events: none;
    }

    &::placeholder {
        opacity: 0.4;
        color: ${({ theme }) => theme.colors.darkGray};
    }

    ${({ inputSize }) => inputSizesMobile[inputSize]}
    ${({ inputSize }) => laptopUpMedia(inputSizes[inputSize])}
`;
