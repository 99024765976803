import { ForgotPassword } from 'components/ForgotPassword';
import { LoginForm } from 'components/LoginForm';
import { PageForm } from 'components/PageForm';
import { Popup } from 'components/Popup';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIsAuth } from 'store/auth';

export const LoginPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isShowForgotPopup, setIsShowForgotPopup] = useState(false);
    const isAuth = useSelector(getIsAuth);

    const handlerOpenForgotPopup = useCallback(() => {
        setIsShowForgotPopup(true);
    }, []);

    const handlerCloseForgotPopup = useCallback(() => {
        setIsShowForgotPopup(false);
    }, []);

    useEffect(() => {
        if (isAuth && location?.state?.from) {
            navigate(location.state.from.pathname, {
                state: { from: undefined },
            });
        } else if (isAuth) {
            navigate('/');
        }
    }, [isAuth, navigate, location]);

    return (
        <PageForm name="Вход">
            <LoginForm onForgotPasswordClick={handlerOpenForgotPopup} />
            <Popup isShow={isShowForgotPopup} onClose={handlerCloseForgotPopup}>
                <ForgotPassword />
            </Popup>
        </PageForm>
    );
};
