import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIsAuth } from 'store/auth';

export const ProtectedRoute = () => {
    const isAuth = useSelector(getIsAuth);
    const location = useLocation();

    if (isAuth || localStorage.getItem('token')) {
        return <Outlet />;
    }

    return <Navigate to="/login" state={{ from: location }} />;
};
