import { Module } from '../../data';

export interface FilterValues {
    type: string;
    group: string;
}

/**
 * Фильтруем модули и темы
 * @param {Module[]} modules Данные модулей
 * @param {FilterValues} filter Параметры фильтра
 * @returns {Module[]}
 */
const filter = (modules: Module[], filter: FilterValues): Module[] => {
    const { group, type } = filter;
    const data: Module[] = [];

    modules.forEach((module) => {
        const filteredModule = { ...module };
        const subThemes = module.themes.filter(
            (subTheme) =>
                ((subTheme.groups && subTheme.groups.includes(group)) || group === '') &&
                ((subTheme.types && subTheme.types.includes(type)) || type === '')
        );

        if (subThemes.length > 0 || (group === '' && type === '')) {
            filteredModule.themes = subThemes;
            data.push(filteredModule);
        }
    });

    return data;
};

export { filter };
