import { createSlice } from '@reduxjs/toolkit';
import { fetchBadges } from '../services/fetchBadges';
import { BadgesSchema } from '../types/BadgesSchema';

const initialState: BadgesSchema = {
    loading: false,
    error: undefined,
    badges: undefined,
};

export const badgesSlice = createSlice({
    name: 'badges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBadges.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(fetchBadges.fulfilled, (state, action) => {
            state.loading = false;
            state.badges = action.payload;
        });
        builder.addCase(fetchBadges.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { actions: badgesActions } = badgesSlice;
export const { reducer: badgesReducer } = badgesSlice;
