import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ThunkConfig } from 'store';
import { API_URL } from 'config';
import { AuthResponse } from '../models/AuthResponse';

export const checkAuth = createAsyncThunk<
    AuthResponse,
    undefined,
    ThunkConfig<string>
>('auth/checkAuth', async (_, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    try {
        const response = await axios.get<AuthResponse>(
            `${API_URL}/auth/refresh`,
            { withCredentials: true }
        );

        if (!response.data) {
            throw new Error();
        }

        localStorage.setItem('token', response.data.accessToken);
        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка проверки токена');
    }
});
