import React, { ImgHTMLAttributes, memo } from 'react';
import LightLogo from 'assets/images/light.svg';
import DarkLogo from 'assets/images/dark.svg';

const mapping = {
    light: LightLogo,
    dark: DarkLogo,
};

interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {
    variant: keyof typeof mapping;
}

export const Logo = memo((props: LogoProps) => {
    const { variant, width, height, ...otherProps } = props;
    return <img src={mapping[variant]} width={width} height={height} alt="" {...otherProps} />;
});
