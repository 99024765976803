import { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import { laptopDown, laptopUp } from 'config';

export const laptopUpMedia = (inner?: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
    @media (min-width: ${laptopUp}) {
        ${inner};
    }
`;

export const laptopDownMedia = (inner?: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
    @media (max-width: ${laptopDown}) {
        ${inner};
    }
`;
