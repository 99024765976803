import { createGlobalStyle } from 'styled-components';

export const FontsStyles = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/montserrat.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/montserrat-italic.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("/fonts/inter.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("/fonts/inter-italic.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Ubuntu";
    src: url("/fonts/ubuntu.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Ubuntu";
    src: url("/fonts/ubuntu-italic.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Ubuntu";
    src: url("/fonts/ubuntu.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Ubuntu";
    src: url("/fonts/ubuntu-italic.woff2") format("woff2-variations");
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
  }
`;
