import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { BadgeResponse } from '../models/BadgeResponse';

export const fetchBadges = createAsyncThunk<
    BadgeResponse[],
    undefined,
    ThunkConfig<string>
>('badges/fetchBadges', async (_, ThunkApi) => {
    const { extra, rejectWithValue } = ThunkApi;

    try {
        const response = await extra.api.get<BadgeResponse[]>('/badges');

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка получения бейджей');
    }
});
