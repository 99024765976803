import styled from 'styled-components';
import * as C from 'styles/components';

export const Wrapper = styled.section`
    height: 100vh;
    color: ${({ theme }) => theme.colors.bg};
`;

export const StyledContainer = styled(C.Container)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
`;

export const Status = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: 350px;
    line-height: 1.1;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.accent};
    margin-top: -80px;
    margin-bottom: 24px;
`;

export const Head = styled(C.Title)`
    font-size: ${({ theme }) => `${theme.fontSize.xxxl}px`};
    line-height: 1.2;
    margin-bottom: 18px;
`;

export const Descr = styled(C.Text)`
    max-width: 715px;
    text-align: center;
    margin-bottom: 54px;
`;
