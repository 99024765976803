import { createSlice } from '@reduxjs/toolkit';
import { fetchProgress } from '../services/fetchProgress';
import { CommonSchema } from '../types/CommonSchema';

const initialState: CommonSchema = {
    loading: false,
    error: undefined,
    progress: undefined,
};

export const commonSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProgress.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchProgress.fulfilled, (state, action) => {
                state.progress = action.payload;
                state.loading = false;
                state.error = '';
            })
            .addCase(fetchProgress.rejected, (state) => {
                state.loading = true;
                state.error = 'error';
            });
    },
});

export const { actions: commonActions } = commonSlice;
export const { reducer: commonReducer } = commonSlice;
