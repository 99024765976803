import { memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import * as S from './styles';

interface PopupProps {
    isShow: boolean;
    overlay?: boolean;
    children: ReactNode;
    className?: string;
    onClose?: () => void;
}

export const Popup = memo((props: PopupProps) => {
    const { isShow, overlay = true, children, className, onClose } = props;
    const ref = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const handlerClickOutside = useCallback(
        (e: MouseEvent) => {
            if (ref?.current?.contains(e.target as Node)) return;

            onClose?.();
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handlerClickOutside);
        return () => document.removeEventListener('mousedown', handlerClickOutside);
    }, [handlerClickOutside]);

    const popup = (
        <S.Popup ref={ref} className={className}>
            {children}
        </S.Popup>
    );

    if (overlay) {
        return createPortal(
            <CSSTransition nodeRef={overlayRef} in={isShow} timeout={200} unmountOnExit>
                <S.Overlay ref={overlayRef}>{popup}</S.Overlay>
            </CSSTransition>,
            document.body
        );
    }

    return (
        <CSSTransition nodeRef={ref} in={isShow} timeout={200} unmountOnExit>
            {popup}
        </CSSTransition>
    );
});
