import { createSlice } from '@reduxjs/toolkit';
import { saveBadge } from 'store/profile/services/saveBadge';
import { updateProgress } from '../services/updateProgress';
import { fetchProfile } from '../services/fetchProfile';
import { ProfileSchema } from '../types/ProfileSchema';

const initialState: ProfileSchema = {
    loading: false,
    error: undefined,
    profile: undefined,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProfile.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload;
        });
        builder.addCase(fetchProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(updateProgress.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(updateProgress.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload;
        });
        builder.addCase(updateProgress.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(saveBadge.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(saveBadge.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(saveBadge.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { reducer: profileReducer } = profileSlice;
export const { actions: profileActions } = profileSlice;
