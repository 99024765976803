import { ProtectedRoute } from 'components/ProtectedRoute';
import { SharedRoute } from 'components/SharedRoute';
import { ForgotPassword } from 'pages/ForgotPassword';
import { LoginPage } from 'pages/LoginPage';
import { PersdataPage } from 'pages/PersdataPage';
import { ProfilePage } from 'pages/ProfilePage';
import { RegisterPage } from 'pages/RegisterPage';
import { TermsofusePage } from 'pages/TermsofusePage';
import { TextPage } from 'pages/TextPage';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage, HomePage } from 'pages/';

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    {
        element: <TextPage />,
        children: [
            {
                path: '/termsofuse',
                element: <TermsofusePage />,
            },
            {
                path: '/persdata',
                element: <PersdataPage />,
            },
        ],
    },
    {
        element: <ProtectedRoute />,
        children: [
            {
                path: '/profile',
                element: <ProfilePage />,
            },
        ],
    },
    {
        element: <SharedRoute />,
        children: [
            {
                path: '/login',
                element: <LoginPage />,
            },
            {
                path: '/register',
                element: <RegisterPage />,
            },
            {
                path: '/forgot/:token',
                element: <ForgotPassword />,
            },
        ],
    },
    {
        path: '*',
        errorElement: <ErrorPage />,
    },
]);
