import { memo, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'store/auth';
import { Badge } from 'store/badges';
import _ from 'lodash';
import {
    fetchProfile,
    getProfileBadges,
    getProfileProgress,
    updateProgress,
    saveBadge,
    getProfile,
} from 'store/profile';
import { useAppDispatch } from 'utils/hooks';

interface SyncProgressProps {
    children: ReactNode;
}

const findNewBadges = (localBadges: Badge[], savedBadges: Badge[]) => {
    const result: Badge[] = [];

    for (let i = 0; i < localBadges.length; i++) {
        const isSaved = savedBadges.find(
            (badge) => badge.alias === localBadges[i].alias
        );

        if (!isSaved) {
            result.push(localBadges[i]);
        }
    }

    return result;
};

export const SyncProfile = memo((props: SyncProgressProps) => {
    const { children } = props;
    const [isBadgesUpdated, setIsBadgesUpdated] = useState(false);
    const savedProgress = useSelector(getProfileProgress);
    const savedBadges = useSelector(getProfileBadges);
    const isAuth = useSelector(getIsAuth);
    const profile = useSelector(getProfile);
    const dispatch = useAppDispatch();

    // Синхронизация прогресса
    useEffect(() => {
        if (isAuth) {
            const localProgressStr = localStorage.getItem('progress');

            if (profile && savedProgress !== undefined && !localProgressStr) {
                // Сохраненный прогресс в локалсторадж
                if (process.env.NODE_ENV === 'development') {
                    console.log('[PROGRESS TO LOCAL STORAGE]');
                }

                localStorage.setItem('progress', JSON.stringify(savedProgress));
            } else if (profile && !savedProgress && localProgressStr) {
                // Локальный прогресс в базу
                if (process.env.NODE_ENV === 'development') {
                    console.log('[PROGRESS TO DB]');
                }

                dispatch(updateProgress(JSON.parse(localProgressStr)));
            } else if (
                profile &&
                savedProgress !== undefined &&
                localProgressStr &&
                !_.isEqual(savedProgress, JSON.parse(localProgressStr))
            ) {
                // Объединяем сохраненный и локальный прогресс
                if (process.env.NODE_ENV === 'development') {
                    console.log('[MERGE PROGRESS]');
                }

                dispatch(
                    updateProgress({
                        ...savedProgress,
                        ...JSON.parse(localProgressStr),
                    })
                );
            }
        }
    }, [dispatch, isAuth, profile, savedProgress]);

    // Синхронизация бейджей
    useEffect(() => {
        if (isAuth) {
            const localBadgesStr = localStorage.getItem('badges');

            if (
                profile &&
                savedBadges &&
                savedBadges.length > 0 &&
                !localBadgesStr
            ) {
                // Сохраненные бейджи в локалсторадж
                if (process.env.NODE_ENV === 'development') {
                    console.log('[BADGES TO LOCAL STORAGE]');
                }

                localStorage.setItem('badges', JSON.stringify(savedBadges));
            } else if (
                profile &&
                savedBadges &&
                savedBadges.length === 0 &&
                localBadgesStr
            ) {
                if (!isBadgesUpdated) {
                    // Локальные бейджи в базу
                    if (process.env.NODE_ENV === 'development') {
                        console.log('[BADGES TO DB]');
                    }

                    JSON.parse(localBadgesStr).forEach((badge: Badge) => {
                        dispatch(saveBadge({ alias: badge.alias }));
                    });

                    setIsBadgesUpdated(true);
                }
            } else if (
                profile &&
                savedBadges &&
                savedBadges.length > 0 &&
                localBadgesStr
            ) {
                // Объединяем сохраненные и локальные бейджи
                const newBadges = findNewBadges(
                    JSON.parse(localBadgesStr),
                    savedBadges
                );

                if (newBadges.length > 0 && !isBadgesUpdated) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('[MERGE BADGES]');
                    }

                    newBadges.forEach((badge: Badge) => {
                        dispatch(saveBadge({ alias: badge.alias }));
                    });

                    setIsBadgesUpdated(true);
                }
            }
        }
    }, [dispatch, isAuth, savedBadges, profile, isBadgesUpdated]);

    useEffect(() => {
        if (isAuth) {
            dispatch(fetchProfile());
        }
    }, [dispatch, isAuth]);

    useEffect(() => {
        if (isBadgesUpdated) {
            dispatch(fetchProfile());
        }
    }, [dispatch, isBadgesUpdated]);

    return <>{children}</>;
});
