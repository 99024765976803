export enum ButtonColors {
    accent = 'accent',
    veryLightGray = 'veryLightGray',
    dark = 'dark',
}

export enum ButtonSizes {
    s = 's',
    m = 'm',
    l = 'l',
}

export enum ButtonVariants {
    solid = 'solid',
    outline = 'outline',
    ghost = 'ghost',
    progress = 'progress',
}
