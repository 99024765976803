import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { AuthResponse } from '../models/AuthResponse';

export const register = createAsyncThunk<
    AuthResponse,
    { name: string; email: string; phone?: string; password: string },
    ThunkConfig<string>
>('auth/register', async (registrationData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<AuthResponse>(
            '/auth/registration',
            registrationData
        );

        if (!response.data) {
            throw new Error();
        }

        localStorage.setItem('token', response.data.accessToken);
        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при регистрации');
    }
});
