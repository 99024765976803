import { ForgotForm } from 'components/ForgotPasswordForm';
import { memo } from 'react';
import * as S from './styles';

export const ForgotPassword = memo(() => (
    <S.ForgotFormWrap>
        <S.Title>Забыли пароль?</S.Title>
        <ForgotForm />
    </S.ForgotFormWrap>
));
