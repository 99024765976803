import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';
import * as C from 'styles/components';

export const ForgotFormWrap = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 42px 32px;

    ${laptopUpMedia(css`
        width: 100vw;
        max-width: 600px;
        padding: 56px 64px;
    `)}
`;

export const Title = styled(C.FormTitle)`
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.accent};
    text-align: center;
    margin-bottom: 42px;

    ${laptopUpMedia(css`
        margin-bottom: 64px;
    `)}
`;
