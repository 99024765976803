import { createSlice } from '@reduxjs/toolkit';
import { changePassword } from '../services/changePassword';
import { forgotPassword } from '../services/forgotPassword';
import { register } from '../services/register';
import { checkAuth } from '../services/checkAuth';
import { login } from '../services/login';
import { logout } from '../services/logout';
import { AuthSchema } from '../types/AuthSchema';

const initialState: AuthSchema = {
    loading: false,
    error: undefined,
    isAuth: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(login.fulfilled, (state) => {
            state.loading = false;
            state.isAuth = true;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(checkAuth.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(checkAuth.fulfilled, (state) => {
            state.loading = false;
            state.isAuth = true;
        });
        builder.addCase(checkAuth.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(register.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(register.fulfilled, (state) => {
            state.loading = false;
            state.isAuth = true;
        });
        builder.addCase(register.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(logout.pending, (state) => {
            state.loading = false;
            state.error = undefined;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.loading = false;
            state.isAuth = false;
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(changePassword.pending, (state) => {
            state.loading = false;
            state.error = undefined;
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(changePassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(forgotPassword.pending, (state) => {
            state.loading = false;
            state.error = undefined;
        });
        builder.addCase(forgotPassword.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { actions: authActions } = authSlice;
export const { reducer: authReducer } = authSlice;
