import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import PromoBg from 'assets/images/promo-bg.webp';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';

export const Container = styled.div<{ fullHeight?: boolean; narrow?: boolean }>`
    width: 100%;
    height: ${({ fullHeight }) => fullHeight && '100svh'};
    max-width: ${({ theme, narrow }) =>
        `${theme[narrow ? 'narrowMaxWidth' : 'maxWidth']}px`};
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
`;

export const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('${PromoBg}') center center / cover no-repeat;
    background-color: ${({ theme }) => theme.colors.promoBg};
    overflow: hidden;
    z-index: 10;

    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 190%;
        height: 190%;
        background: radial-gradient(
            50% 50% at 50% 50%,
            #186dec 0%,
            rgba(24, 109, 236, 0) 100%
        );
        mix-blend-mode: color;
        opacity: 0.6;
        pointer-events: none;
        z-index: 10;
    }

    &::before {
        left: -100%;
        top: -114%;
    }

    &::after {
        left: -3%;
        top: 8%;
    }
`;

export const Br = styled.br<{ ignore?: boolean }>`
    ${({ ignore }) =>
        ignore &&
        css`
            display: none;
        `}
`;

export const ProgressBar = styled.progress`
    width: 100%;
    height: 4px;
    background: rgba(225, 227, 231, 0.5);
    border-radius: 100px;
    border: 0;

    &::-webkit-progress-value {
        background: ${({ theme }) => theme.colors.accent};
        border-radius: 100px 0 0 100px;
    }

    &::-webkit-progress-bar {
        background: rgba(225, 227, 231, 0.5);
        border-radius: 100px;
    }

    &::-moz-progress-bar {
        background: ${({ theme }) => theme.colors.accent};
        border-radius: 100px 0 0 100px;
    }
`;

export const Text = styled.div`
    h1 {
        text-transform: uppercase;
        font-size: ${({ theme }) => theme.fontSize.xxl}px;
    }

    h2 {
        text-transform: uppercase;
        font-size: ${({ theme }) => theme.fontSize.xl}px;
    }

    p {
        margin: 20px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    b {
        font-weight: 700;
    }

    ul {
        padding-left: 20px;
        margin: 20px 0;

        &:first-child,
        &:last-child {
            margin: 0;
        }
    }

    a {
        color: ${({ theme }) => theme.colors.accent};
        text-decoration-thickness: 1px;
    }
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.familySecondary};
    font-weight: 700;
    line-height: 1;
    margin: 0;
`;

export const Label = styled.div`
    font-family: ${({ theme }) => theme.familyPrimary};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;

    ${laptopUpMedia(css`
        font-size: ${({ theme }) => theme.fontSize.l}px;
    `)}
`;

export const Avatar = styled.img<{ size?: number }>`
    width: ${({ size }) => size ?? 44}px;
    height: ${({ size }) => size ?? 44}px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    background: ${({ theme }) => theme.colors.lightGray};
    box-shadow: 0 16px 20px ${rgba('#343A45', 0.1)};
`;

export const Card = styled.div<{ isBanner?: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 24px;
    box-sizing: border-box;
    margin-top: 54px;

    ${({ isBanner }) =>
        isBanner &&
        laptopDownMedia(css`
            background-color: #f5f5f5;
            padding: 24px;
        `)}

    ${laptopUpMedia(css`
        padding: 64px;
        margin-top: 32px;
    `)}
`;

export const Error = styled.div<{ align?: 'left' | 'center' }>`
    font-size: ${({ theme }) => theme.fontSize.m}px;
    text-align: ${({ align }) => align || 'left'};
    color: ${({ theme }) => theme.colors.error};
    margin-top: 8px;
`;

export const Success = styled.div<{ align?: 'left' | 'center' }>`
    font-size: ${({ theme }) => theme.fontSize.m}px;
    color: ${({ theme }) => theme.colors.accent};
    text-align: ${({ align }) => align || 'left'};
    margin-top: 8px;
`;

export const FormTitle = styled.div`
    width: 100%;
    font-family: ${({ theme }) => theme.familySecondary};
    font-size: 36px;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
    line-height: 1;

    ${laptopUpMedia(css`
        text-align: center;
        font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    `)}
`;
