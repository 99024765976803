import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/user';
import { checkModuleAccess } from 'utils/helpers';

export const useIsAccess = (roles?: string[]) => {
    const [isAccess, setIsAccess] = useState(false);
    const user = useSelector(getUser);

    useEffect(() => {
        let access = false;

        if (!roles) {
            access = true;
        } else if (localStorage.getItem('token') && user?.roles) {
            access = checkModuleAccess(roles, user.roles);
        }

        setIsAccess(access);
    }, [roles, user]);

    return isAccess;
};
