import React, { useEffect, useState } from 'react';

/**
 * Получаем коэффициент прокрутки для sticky блока
 * @param {React.RefObject<HTMLElement>} ref Ссылка на элемент
 * @param {number} correct Корректировка
 * @returns {number}
 */
export const useScrolledWrapper = (ref: React.RefObject<HTMLElement>, correct?: number): number => {
    const [position, setPosition] = useState(0);

    const handleScroll = () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            let scroll = document.documentElement.clientHeight - rect.top;

            if (correct) {
                scroll -= correct;
            }

            setPosition(Math.round((scroll / ref.current.offsetHeight) * 100) / 100);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return position;
};
