import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input';
import styled from 'styled-components';
import * as C from 'styles/components';

export const Label = styled(C.Label)`
    margin-bottom: 8px;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 32px;
`;

export const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 32px;
`;
