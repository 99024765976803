import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { ModulesProgress } from 'types/Progress';
import { ProfileResponse } from '../model/ProfileResponse';

export const updateProgress = createAsyncThunk<
    ProfileResponse,
    ModulesProgress,
    ThunkConfig<string>
>('profile/updateProgress', async (progress, ThunkApi) => {
    const { extra, rejectWithValue } = ThunkApi;

    try {
        const response = await extra.api.put<ProfileResponse>('/profiles/me', {
            progress,
        });

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при обновлении прогресса');
    }
});
