import { HTMLAttributes, memo, ReactNode, useCallback, useState } from 'react';
import * as S from './styles';

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
    className?: string;
    children?: ReactNode;
    onCheck: (isChecked: boolean) => void;
}

export const Checkbox = memo((props: CheckboxProps) => {
    const { children, onCheck, className, ...other } = props;
    const [isChecked, setIsChecked] = useState(false);

    const handlerChange = useCallback(() => {
        setIsChecked((isChecked) => {
            onCheck(!isChecked);
            return !isChecked;
        });
    }, [onCheck]);

    return (
        <S.Checkbox className={className} isChecked={isChecked}>
            <S.Input
                type="checkbox"
                checked={isChecked}
                onChange={handlerChange}
                {...other}
            />
            <S.Label>{children}</S.Label>
        </S.Checkbox>
    );
});
