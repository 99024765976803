import { Button, ButtonSizes } from 'components/Button';
import { InputColors, InputSizes, InputVariants } from 'components/Input';
import * as S from 'components/LoginForm/styles';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { forgotPassword, getAuthIsError, getAuthIsLoading } from 'store/auth';
import * as C from 'styles/components';
import { validateEmail } from 'utils/helpers';
import { useAppDispatch } from 'utils/hooks';

export const ForgotForm = memo(() => {
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const isAuthLoading = useSelector(getAuthIsLoading);
    const isServerError = useSelector(getAuthIsError);

    const dispatch = useAppDispatch();

    const handlerEmailChange = useCallback((value: string) => {
        setIsSuccess(false);
        setIsError(false);
        setIsEmailError(false);
        setEmail(value);
    }, []);

    const handlerForgotClick = useCallback(() => {
        if (email.length === 0) {
            setIsError(true);
            return;
        }

        if (validateEmail(email)) {
            setIsEmailError(true);
            return;
        }

        setIsSuccess(true);

        dispatch(forgotPassword({ email }));

        if (!isServerError) {
            setEmail('');
        }
    }, [email, dispatch, isServerError]);

    return (
        <>
            <S.Label>Введите e-mail</S.Label>
            <S.StyledInput
                type="text"
                value={email}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                required
                onChange={handlerEmailChange}
            />
            <Button
                size={ButtonSizes.l}
                iconRight={isAuthLoading ? 'loader' : 'arrow-right'}
                iconColor="white"
                isIconRightAnimation={isAuthLoading}
                disabled={!email || isAuthLoading}
                onClick={handlerForgotClick}
            >
                Отправить
            </Button>
            {isError && <C.Error>Заполните все обязательные поля</C.Error>}
            {isEmailError && <C.Error>Введите корректный email</C.Error>}
            {isServerError && (
                <C.Error>
                    Пользователь с таким Email не зарегистрирован на сайте.
                </C.Error>
            )}
            {isSuccess && !isServerError && (
                <C.Success>
                    На вашу почту отправлена ссылка для восстановления пароля.
                </C.Success>
            )}
        </>
    );
});
