import { Button, ButtonSizes } from 'components/Button';
import { InputColors, InputSizes, InputVariants } from 'components/Input';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuthIsError, getAuthIsLoading, register } from 'store/auth';
import { validateEmail } from 'utils/helpers';
import { useAppDispatch } from 'utils/hooks';
import * as C from 'styles/components';
import * as S from './styles';

export const RegisterForm = memo(() => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkAgreed, setCheckAgreed] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [isError, setIsError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isAgreedError, setIsAgreedError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);

    const dispatch = useAppDispatch();
    const isAuthLoading = useSelector(getAuthIsLoading);
    const isServerError = useSelector(getAuthIsError);

    const handlerNameChange = useCallback((value: string) => {
        setIsError(false);
        setName(value);
    }, []);

    const handlerEmailChange = useCallback((value: string) => {
        setIsError(false);
        setIsEmailError(false);
        setEmail(value);
    }, []);

    const handlerPasswordChange = useCallback((value: string) => {
        setIsError(false);
        setIsPasswordError(false);
        setPassword(value);
    }, []);

    const handlerConfirmPasswordChange = useCallback((value: string) => {
        setIsError(false);
        setConfirmPassword(value);
    }, []);

    const handlerCheckAgree = useCallback((isChecked: boolean) => {
        setIsAgreedError(false);
        setCheckAgreed(isChecked);
    }, []);

    const handlerPasswordVisibleClick = useCallback(() => {
        setPasswordVisible((passwordVisible) => !passwordVisible);
    }, []);

    const handlerLoginClick = useCallback(() => {
        if (
            name.length === 0 ||
            email.length === 0 ||
            email.length === 0 ||
            password.length === 0
        ) {
            setIsError(true);
            return;
        }

        if (validateEmail(email)) {
            setIsEmailError(true);
            return;
        }

        if (password !== confirmPassword) {
            setIsPasswordError(true);
            return;
        }

        if (!checkAgreed) {
            setIsAgreedError(true);
            return;
        }

        dispatch(
            register({
                name,
                email,
                password,
            })
        );
    }, [name, email, password, confirmPassword, checkAgreed, dispatch]);

    return (
        <>
            <S.Label>Введите имя*</S.Label>
            <S.StyledInput
                type="text"
                value={name}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                required
                onChange={handlerNameChange}
            />
            <S.Label>E-mail*</S.Label>
            <S.StyledInput
                type="text"
                value={email}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                required
                onChange={handlerEmailChange}
            />
            <S.Label>Введите пароль</S.Label>
            <S.StyledInput
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                iconRight={passwordVisible ? 'eye-open' : 'eye-off'}
                onRightIconClick={handlerPasswordVisibleClick}
                opacityIcons
                required
                onChange={handlerPasswordChange}
            />
            <S.Label>Подтвердите пароль*</S.Label>
            <S.StyledInput
                type={passwordVisible ? 'text' : 'password'}
                value={confirmPassword}
                variant={InputVariants.outline}
                color={InputColors.lightGray}
                inputSize={InputSizes.s}
                iconRight={passwordVisible ? 'eye-open' : 'eye-off'}
                onRightIconClick={handlerPasswordVisibleClick}
                opacityIcons
                required
                onChange={handlerConfirmPasswordChange}
            />
            <S.StyledCheckbox onCheck={handlerCheckAgree}>
                Я соглашаюсь на{' '}
                <Link to="/persdata">обработку персональных данных</Link> и{' '}
                <Link to="/termsofuse">
                    политикой конфиденциальности и условиями использования
                </Link>
            </S.StyledCheckbox>
            <Button
                size={ButtonSizes.l}
                iconRight={isAuthLoading ? 'loader' : 'arrow-right'}
                iconColor="white"
                isIconRightAnimation={isAuthLoading}
                disabled={
                    !name ||
                    !email ||
                    !password ||
                    !confirmPassword ||
                    !checkAgreed ||
                    isAuthLoading
                }
                onClick={handlerLoginClick}
            >
                Регистрация
            </Button>
            {isError && <C.Error>Заполните все обязательные поля</C.Error>}
            {isEmailError && <C.Error>Введите корректный email</C.Error>}
            {isAgreedError && <C.Error>Примите соглашения</C.Error>}
            {isPasswordError && <C.Error>Пароли не совпадают</C.Error>}
            {isServerError && (
                <C.Error>Такой пользователь уже существует.</C.Error>
            )}
        </>
    );
});
