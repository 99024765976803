import styled, { css } from 'styled-components';
import * as C from 'styles/components';
import Check from 'assets/icons/checkbox.svg';

export const Checkbox = styled.label<{ isChecked: boolean }>`
    position: relative;
    display: flex;
    overflow: hidden;
    column-gap: 16px;

    &:before {
        display: block;
        content: '';
        width: 27px;
        height: 27px;
        border-radius: 6px;
        cursor: pointer;
        flex-shrink: 0;

        ${({ isChecked }) =>
            ({ theme }) => css`
                border: 2px solid ${theme.colors.accent};

                ${isChecked &&
                css`
                    background: url(${Check}) center center no-repeat
                        ${theme.colors.accent};
                `}
            `}}


}
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    left: -9999px;
`;

export const Label = styled(C.Text)`
    font-size: ${({ theme }) => theme.fontSize.m}px;
    font-weight: 500;
    line-height: 1.2;
`;
