import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';

export const UserName = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    align-items: center;
    column-gap: 24px;
    font-size: ${({ theme }) => theme.fontSize.s}px;
    color: ${({ variant, theme }) =>
        variant === 'light' ? theme.colors.white : theme.colors.dark};
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
`;

export const Welcome = styled.span`
    display: none;

    ${laptopUpMedia(css`
        display: block;
    `)}
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
`;
