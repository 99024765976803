import { FooterColors } from 'components/Footer/types';
import { StyledVariants } from 'types/StyledVariants';
import { Text } from 'styles/components';
import styled, { css } from 'styled-components';
import { laptopUpMedia } from 'styles/helpers';

const colors: StyledVariants<FooterColors> = {
    gray: css`
        background-color: ${({ theme }) => theme.colors.bg};
    `,
    white: css`
        ${laptopUpMedia(css`
            background-color: ${({ theme }) => theme.colors.white};
        `)}
    `,
};

export const Footer = styled.footer<{ color: FooterColors }>`
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    ${({ color }) => colors[color]}
`;

export const TelegramLink = styled.a`
    display: inline-block;
    margin-bottom: 32px;
`;

export const Copyright = styled(Text)`
    font-family: ${({ theme }) => theme.familySecondary};
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 0.4;
    font-weight: 500;
`;
