import { StyledTheme } from 'types/StyledTheme';

export const baseTheme: StyledTheme = {
    familyPrimary: 'Ubuntu, sans-serif',
    familySecondary: 'Montserrat, sans-serif',
    maxWidth: 1248,
    narrowMaxWidth: 796,
    fontSize: {
        default: 16,
        xxxl: 56,
        xxl: 36,
        xl: 24,
        l: 18,
        m: 14,
        s: 12,
        xs: 11,
        xxs: 10,
    },
    colors: {
        white: '#FFFFFF',
        text: '#343A45',
        bg: '#F2F2F2',
        promoBg: '#171B23',
        veryLightGray: '#E0E0E0',
        lightGray: '#B5B9C7',
        gray: '#7D7F8A',
        darkGray: '#343A45',
        dark: '#16171C',
        lightAccent: '#4285F4',
        accent: '#186DEC',
        error: '#F54056',
    },
};

export const mobileTheme: StyledTheme = {
    ...baseTheme,
    fontSize: {
        default: 16,
        xxxl: 32,
        xxl: 24,
        xl: 20,
        l: 18,
        m: 14,
        s: 12,
        xs: 12,
        xxs: 10,
    },
};
