import { ForgotPassword } from 'components/ForgotPassword';
import { LoginForm } from 'components/LoginForm';
import { Popup } from 'components/Popup';
import React, { memo, useCallback, useState } from 'react';
import { Button, ButtonSizes } from 'components/Button';
import * as S from './styles';

export const LoginButton = memo(() => {
    const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
    const [isShowForgotPopup, setIsShowForgotPopup] = useState(false);

    const handlerOpenLoginPopup = useCallback(() => {
        setIsShowLoginPopup(true);
    }, []);

    const handlerCloseLoginPopup = useCallback(() => {
        setIsShowLoginPopup(false);
    }, []);

    const handlerOpenForgotPopup = useCallback(() => {
        setIsShowLoginPopup(false);
        setIsShowForgotPopup(true);
    }, []);

    const handlerCloseForgotPopup = useCallback(() => {
        setIsShowForgotPopup(false);
    }, []);

    return (
        <>
            <Button size={ButtonSizes.s} onClick={handlerOpenLoginPopup}>
                Войти
            </Button>
            <Popup isShow={isShowLoginPopup} onClose={handlerCloseLoginPopup}>
                <S.LoginFormWrap>
                    <S.Title>Вход</S.Title>
                    <LoginForm onForgotPasswordClick={handlerOpenForgotPopup} />
                </S.LoginFormWrap>
            </Popup>
            <Popup isShow={isShowForgotPopup} onClose={handlerCloseForgotPopup}>
                <ForgotPassword />
            </Popup>
        </>
    );
});
