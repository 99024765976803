import { Input } from 'components/Input';
import styled, { css } from 'styled-components';
import * as C from 'styles/components';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';

export const Label = styled(C.Label)`
    margin-bottom: 8px;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 16px;

    ${laptopUpMedia(css`
        margin-bottom: 32px;
    `)}
`;

export const ForgotPassword = styled.div`
    margin-bottom: 48px;

    ${laptopUpMedia(css`
        margin-bottom: 32px;
        margin-top: -24px;
    `)}
`;

export const ForgotPasswordLink = styled.span`
    color: ${({ theme }) => theme.colors.accent};
    text-decoration-thickness: 1px;
    font-size: ${({ theme }) => theme.fontSize.m}px;
    font-weight: 700;
    line-height: 1.2;
    cursor: pointer;
`;

export const Register = styled(C.Text)`
    font-weight: 700;
    line-height: 1.5;
    margin-top: 48px;

    ${laptopDownMedia(css`
        font-size: 14px;
    `)}
`;
