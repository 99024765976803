import { LoginButton } from 'components/LoginButton';
import { UserMenu } from 'components/UserMenu';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'store/auth';

interface UserProps {
    variant: 'dark' | 'light';
}

export const UserWidget = memo((props: UserProps) => {
    const { variant } = props;
    const isAuth = useSelector(getIsAuth);

    return (
        <>
            {isAuth || localStorage.getItem('token') ? (
                <UserMenu variant={variant} />
            ) : (
                <LoginButton />
            )}
        </>
    );
});
