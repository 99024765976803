import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { checkAuth, getIsAuth } from 'store/auth';
import { fetchUser } from 'store/user';
import { useAppDispatch } from 'utils/hooks';

interface AuthProps {
    children: ReactNode;
}

export const CheckAuth = (props: AuthProps) => {
    const { children } = props;
    const dispatch = useAppDispatch();
    const isAuth = useSelector(getIsAuth);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(checkAuth());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isAuth) {
            dispatch(fetchUser());
        }
    }, [dispatch, isAuth]);

    return <>{children}</>;
};
