import { FooterColors } from 'components/Footer/types';
import React, { memo } from 'react';
import { Icon } from 'components/Icon';
import { useMatchMedia } from 'utils/hooks';
import { laptopUp } from 'config';
import * as C from 'styles/components';
import * as S from './styles';

interface FooterProps {
    color?: FooterColors;
}

export const Footer = memo((props: FooterProps) => {
    const { color = FooterColors.white } = props;
    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <S.Footer color={color}>
            <C.Container>
                <S.TelegramLink
                    href="https://web.telegram.org/k/#@PlaySkills"
                    target="_blank"
                >
                    <Icon glyph="telegram" size={laptopMatch ? 48 : 32} />
                </S.TelegramLink>
                <S.Copyright>2024 PlaySkills by Gamification Lab</S.Copyright>
            </C.Container>
        </S.Footer>
    );
});
