import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { SuccessResponse } from '../models/SuccessResponse';

export const changePassword = createAsyncThunk<
    SuccessResponse,
    { password: string },
    ThunkConfig<string>
>('auth/changePassword', async (changePasswordData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.patch<SuccessResponse>(
            '/auth/change_password',
            changePasswordData
        );

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при изменении пароля');
    }
});
