import { createSlice } from '@reduxjs/toolkit';
import { updateUser } from 'store/user/services/updateUser';
import { fetchUser } from '../services/fetchUser';
import { UserSchema } from '../types/UserSchema';

const initialState: UserSchema = {
    loading: false,
    error: undefined,
    user: undefined,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(updateUser.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
