import { Module } from '../../data';

export enum FilterName {
    GROUPS = 'groups',
    TYPES = 'types',
}

/**
 * Формируем список доступных значений для фильтра
 * @param {Module[]} modules Данные модулей
 * @param {"themes" | "types"} fieldName Поле
 * @returns {string[]}
 */
const filterValues = (modules: Module[], fieldName: FilterName): string[] => {
    let values: string[] = [];

    modules.forEach(({ themes }) => {
        themes.forEach((subTheme) => {
            const value = subTheme[fieldName];

            if (value) {
                values = [...values, ...value];
            }
        });
    });

    return Array.from(new Set<string>(values)).sort();
};

export { filterValues };
