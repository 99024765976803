import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { SuccessResponse } from '../models/SuccessResponse';

export const forgotPassword = createAsyncThunk<
    SuccessResponse,
    { email: string },
    ThunkConfig<string>
>('auth/forgotPassword', async (forgotPasswordData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<SuccessResponse>(
            '/auth/forgot_password',
            forgotPasswordData
        );

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при восстановлении пароля');
    }
});
