import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';

export const logout = createAsyncThunk<boolean, undefined, ThunkConfig<string>>('auth/logout', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post('/auth/logout');

        if (!response.data) {
            throw new Error();
        }

        localStorage.removeItem('token');
        return true;
    } catch (error) {
        return rejectWithValue('Ошибка при логауте');
    }
});
