import React, { memo, ReactNode } from 'react';
import { Icon, IconType } from 'components/Icon';
import { ButtonSizes, ButtonColors, ButtonVariants } from './types';
import * as S from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: ButtonColors;
    size?: ButtonSizes;
    variant?: ButtonVariants;
    iconLeft?: IconType;
    iconRight?: IconType;
    iconColor?: string;
    progress?: number;
    isIconLeftAnimation?: boolean;
    isIconRightAnimation?: boolean;
    showDivider?: boolean;
    uppercase?: boolean;
    withShadow?: boolean;
    children?: ReactNode;
}

const iconSizeMap: { [key in ButtonSizes]: number } = {
    s: 16,
    m: 20,
    l: 24,
};

export const Button = memo((props: ButtonProps) => {
    const {
        color = ButtonColors.accent,
        size = ButtonSizes.m,
        variant = ButtonVariants.solid,
        iconLeft,
        iconRight,
        iconColor,
        isIconLeftAnimation = false,
        isIconRightAnimation = false,
        showDivider = true,
        uppercase,
        withShadow,
        children,
        ...otherProps
    } = props;

    return (
        <S.Button
            size={size}
            variant={variant}
            color={color}
            uppercase={uppercase}
            withShadow={withShadow}
            {...otherProps}
        >
            {iconLeft && (
                <>
                    <Icon
                        glyph={iconLeft}
                        size={iconSizeMap[size]}
                        color={iconColor}
                        isAnimation={isIconLeftAnimation}
                    />
                    {children && showDivider && <S.Divider size={iconSizeMap[size]} />}
                </>
            )}
            {children}
            {iconRight && (
                <>
                    {children && showDivider && <S.Divider size={iconSizeMap[size]} />}
                    <Icon
                        glyph={iconRight}
                        size={iconSizeMap[size]}
                        color={iconColor}
                        isAnimation={isIconRightAnimation}
                    />
                </>
            )}
        </S.Button>
    );
});
