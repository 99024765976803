import { Button, ButtonSizes } from 'components/Button';
import { Header } from 'components/Header';
import { memo } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import * as C from 'styles/components';
import * as S from './styles';

export const ErrorPage = memo(() => {
    const error = useRouteError();
    const navigate = useNavigate();

    return (
        <>
            <Header />
            <S.Wrapper>
                <C.Background />
                <S.StyledContainer fullHeight>
                    {isRouteErrorResponse(error) && (
                        <>
                            <S.Status>{error.status}</S.Status>
                            <S.Head as="h1">{error.statusText}</S.Head>
                            {error.status === 404 && (
                                <S.Descr>
                                    Кажется, что-то пошло не так :( Страница, которую вы запрашиваете, не существует.
                                    Возможно, она устарела или была удалена. Перейдите на главную, чтобы все исправить!
                                </S.Descr>
                            )}
                        </>
                    )}

                    {!isRouteErrorResponse(error) && <S.Descr>Unknown Error</S.Descr>}

                    <Button size={ButtonSizes.l} withShadow onClick={() => navigate('/')}>
                        Перейти на главную
                    </Button>
                </S.StyledContainer>
            </S.Wrapper>
        </>
    );
});
