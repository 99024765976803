import React, { useEffect } from 'react';

/**
 * Обрабатываем клик вне элемента
 * @param {React.RefObject<HTMLElement>} ref Ссылка на элемент
 * @param {() => void} callback Функция, которая выполняется при клике вне элемента
 */
export const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
    useEffect(() => {
        const handlerClickOutside = (event: MouseEvent) => {
            if (!(event.target instanceof Node && ref.current?.contains(event.target))) {
                callback();
            }
        };

        document.addEventListener('mousedown', handlerClickOutside);

        return () => {
            document.removeEventListener('mousedown', handlerClickOutside);
        };
    }, [callback, ref]);
};
