import { v4 as uuid } from 'uuid';
import { Module, Theme } from 'data';

/**
 * Извлекаем все темы и предварительно обрабатываем их
 * @param {Module[]} modulesData Данные модулей
 * @returns {Theme[]}
 */
const prepareThemesData = (modulesData: Module[]): Theme[] => {
    let subThemesData: Theme[] = [];

    modulesData.forEach((module) => {
        const updatedSubThemes: Theme[] = module.themes.map((subTheme) => {
            const subThemeWithModule = subTheme as Theme;
            subThemeWithModule.id = uuid();
            subThemeWithModule.roles = module.roles;
            return subThemeWithModule;
        });

        subThemesData = [...subThemesData, ...updatedSubThemes];
    });

    return subThemesData;
};

export { prepareThemesData };
