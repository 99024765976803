import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { ProfileResponse } from '../model/ProfileResponse';

export const saveBadge = createAsyncThunk<
    ProfileResponse,
    { alias: string },
    ThunkConfig<string>
>('profile/saveBadge', async (badge, ThunkApi) => {
    const { extra, rejectWithValue } = ThunkApi;

    try {
        const response = await extra.api.post<ProfileResponse>(
            '/profiles/badge',
            {
                alias: badge.alias,
            }
        );

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при обновлении бейджей');
    }
});
