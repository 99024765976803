import { StateSchema } from 'store/types/StateSchema';

export const getProfile = (state: StateSchema) => state.profile.profile;
export const getProfileBadges = (state: StateSchema) =>
    state.profile.profile?.badges;
export const getProfileProgress = (state: StateSchema) =>
    state.profile.profile?.progress;
export const getProfileIsLoading = (state: StateSchema) =>
    state.profile.loading;
export const getProfileIsError = (state: StateSchema) => state.profile.error;
