import { Footer } from 'components/Footer';
import { FooterColors } from 'components/Footer/types';
import { Header } from 'components/Header';
import { laptopUp } from 'config';
import { memo, ReactNode } from 'react';
import { useMatchMedia } from 'utils/hooks';
import * as C from 'styles/components';
import * as S from './styles';

interface PageFormProps {
    name: string;
    children: ReactNode;
}

export const PageForm = memo((props: PageFormProps) => {
    const { name, children } = props;

    const laptopMatch = useMatchMedia(laptopUp);

    return (
        <S.Page>
            <Header showGradientBg />
            <S.Container>
                <C.FormTitle>{name}</C.FormTitle>
                <S.Form>{children}</S.Form>
            </S.Container>
            <Footer
                color={laptopMatch ? FooterColors.gray : FooterColors.white}
            />
        </S.Page>
    );
});
