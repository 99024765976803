import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'store';
import { UserResponse } from '../models/UserResponse';

export const updateUser = createAsyncThunk<
    UserResponse,
    { name?: string; phone?: string; password?: string },
    ThunkConfig<string>
>('user/updateUser', async (updateData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.put<UserResponse>(
            '/users/me',
            updateData
        );

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (error) {
        return rejectWithValue('Ошибка при редактировании пользователя');
    }
});
