import { v4 as uuid } from 'uuid';
import { Module } from '../../data';

/**
 * Предварительно обрабатываем данные модулей
 * @param {Module[]} modulesData Данные модулей
 * @returns {Module[]}
 */
export const prepareModulesData = (modulesData: Module[]): Module[] =>
    modulesData.map((module) => {
        const updatedModule = { ...module } as Module;
        let themes: string[] = [];

        updatedModule.id = uuid();

        module.themes.forEach((subTheme) => {
            if (subTheme.groups) {
                themes = [...themes, ...subTheme.groups];
            }
        });

        updatedModule.groups = Array.from(new Set<string>(themes));
        return updatedModule;
    });
