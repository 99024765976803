import styled, { css, keyframes } from 'styled-components';
import { IconType } from './types';
import { mapping } from './assets';

interface IconProps {
    glyph: IconType;
    size?: number;
    color?: string;
    isAnimation?: boolean;
}

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Icon = styled.div<IconProps>`
    width: ${({ size }) => size || 40}px;
    height: ${({ size }) => size || 40}px;
    animation: 500ms linear infinite;
    animation-name: ${({ isAnimation }) => isAnimation && rotate};

    ${({ glyph, color }) => {
        if (color) {
            return css`
                background-color: ${color};
                mask: url(${mapping[glyph]});
                mask-repeat: no-repeat;
                mask-size: contain;
            `;
        }

        return css`
            background-image: url(${mapping[glyph]});
            background-size: cover;
        `;
    }};
`;
