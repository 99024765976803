import { ButtonSizes, ButtonVariants } from 'components/Button';
import { IconType } from 'components/Icon';
import { InputColors, InputSizes, InputVariants } from 'components/Input';
import React, { InputHTMLAttributes, memo, useState } from 'react';
import * as S from './styles';

type HTMLInputProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
>;

interface InputProps extends HTMLInputProps {
    value?: string;
    inputSize?: InputSizes;
    variant?: InputVariants;
    color?: InputColors;
    emptyIconColor?: string;
    notEmptyIconColor?: string;
    rounded?: boolean;
    iconRight?: IconType;
    iconLeft?: IconType;
    opacityIcons?: boolean;
    isIconLeftAnimation?: boolean;
    isIconRightAnimation?: boolean;
    disabled?: boolean;
    onRightIconClick?: () => void;
    onLeftIconClick?: () => void;
    onChange?: (value: string) => void;
}

export const Input = memo((props: InputProps) => {
    const {
        value,
        inputSize = InputSizes.m,
        variant = InputVariants.solid,
        color = InputColors.white,
        className,
        iconLeft,
        iconRight,
        opacityIcons = true,
        notEmptyIconColor,
        emptyIconColor,
        isIconLeftAnimation = false,
        isIconRightAnimation = false,
        rounded = true,
        disabled = false,
        onLeftIconClick,
        onRightIconClick,
        onChange,
        ...otherProps
    } = props;
    const [isFocus, setIsFocus] = useState(false);

    const handlerFocus = () => {
        setIsFocus(true);
    };

    const handlerUnFocus = () => {
        setIsFocus(false);
    };

    const handlerChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    return (
        <S.Wrapper
            className={className}
            variant={variant}
            wrapperSize={inputSize}
            color={color}
            rounded={rounded}
            isFocus={isFocus}
            isDisabled={disabled}
        >
            {iconLeft && (
                <S.StyledButton
                    size={ButtonSizes.s}
                    variant={ButtonVariants.ghost}
                    iconColor={value ? notEmptyIconColor : emptyIconColor}
                    iconRight={iconLeft}
                    isIconLeftAnimation={isIconLeftAnimation}
                    $isOpacity={opacityIcons && !isFocus && value?.length === 0}
                    onClick={onLeftIconClick}
                    onTouchStart={onLeftIconClick}
                    disabled={disabled}
                />
            )}
            <S.Input
                value={value}
                inputSize={inputSize}
                disabled={disabled}
                onFocus={handlerFocus}
                onBlur={handlerUnFocus}
                onChange={handlerChangeValue}
                {...otherProps}
            />
            {iconRight && (
                <S.StyledButton
                    size={ButtonSizes.s}
                    variant={ButtonVariants.ghost}
                    iconColor={value ? notEmptyIconColor : emptyIconColor}
                    iconRight={iconRight}
                    isIconRightAnimation={isIconRightAnimation}
                    $isOpacity={opacityIcons && !isFocus && value?.length === 0}
                    onClick={onRightIconClick}
                    onTouchStart={onRightIconClick}
                    disabled={disabled}
                />
            )}
        </S.Wrapper>
    );
});
