import { configureStore } from '@reduxjs/toolkit';
import $api from 'api';
import { authReducer } from 'store/auth';
import { badgesReducer } from 'store/badges';
import { commonReducer } from 'store/common';
import { profileReducer } from 'store/profile';
import { userReducer } from 'store/user';
import { ThunkExtraArg } from './types/StateSchema';

const rootReducers = {
    common: commonReducer,
    auth: authReducer,
    user: userReducer,
    profile: profileReducer,
    badges: badgesReducer,
};

const extraArg: ThunkExtraArg = {
    api: $api,
};

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: extraArg,
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
