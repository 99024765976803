import styled, { css } from 'styled-components';
import * as C from 'styles/components';
import { laptopDownMedia, laptopUpMedia } from 'styles/helpers';

export const Page = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
    padding-top: 120px;
    box-sizing: border-box;

    ${laptopUpMedia(css`
        padding-top: 180px;
    `)}

    ${laptopDownMedia(css`
        background: #ffffff;
    `)}
`;

export const Container = styled(C.Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Form = styled.div`
    margin-top: 48px;

    ${laptopUpMedia(css`
        max-width: 600px;
        border-radius: 24px;
        background: #ffffff;
        box-sizing: border-box;
        padding: 64px;
    `)}
`;
