import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.familyPrimary};
    font-size: ${({ theme }) => `${theme.fontSize.default}px`};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    line-height: 1.6;
    background: ${({ theme }) => theme.colors.bg};
    margin: 0;
  }
`;
