import { useEffect, useState } from 'react';

/**
 * Определяем тип устройства
 * @param {string} breakpoint Точка перелома
 * @returns {boolean}
 */
export const useMatchMedia = (breakpoint: string): boolean | undefined => {
    const [matches, setMatches] = useState<boolean | undefined>(undefined);

    const handlerChange = (event: MediaQueryListEvent) => {
        setMatches(event.matches);
    };

    useEffect(() => {
        const matchMedia = window.matchMedia(`(min-width: ${breakpoint})`);
        matchMedia?.addEventListener('change', handlerChange);
        setMatches(matchMedia.matches);
    }, [breakpoint]);

    return matches;
};
